import Ready from '@/utils/ready';
// @ts-ignore
import * as Swiper from 'swiper/js/swiper.js';

const selector = '[data-ref="slider"]';

class Slider {
  el: any;
  instance: any = null;
  pagination: any;
  paginationItems: any;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('.swiper-container');
    this.pagination = this.el.querySelector(`[data-ref="pagination"]`);
    if (this.pagination) {
      this.paginationItems = this.pagination.querySelectorAll(`[data-ref="paginationItem"]`);
    }
    this.instantiate(this.el, options);
    this.addEventListeners();
  }

  private instantiate(el: any, options: any) {
    let slideLength = el.querySelectorAll('.swiper-slide').length > 1;
    this.instance = new Swiper.default(el, {
      allowSlidePrev: slideLength,
      allowSlideNext: slideLength,
      slidesPerView: 1,
      ...options,
    });
    this.instance.init();
  }

  private addEventListeners() {
    if (this.paginationItems) {
      this.paginationItems.forEach((item: any, index: number) => {
        item.addEventListener('click', () => {
          this.instance.slideTo(index);
        });
      });
    }
    this.instance.on('slideChange', () => {
      this.el.dataset.currentSlide = this.instance.activeIndex;
      if (this.paginationItems) {
        this.paginationItems.forEach((item: any, index: number) => {
          if (this.instance.activeIndex === index) {
            item.classList.add('pagination__item--active');
          } else {
            item.classList.remove('pagination__item--active');
          }
        });
      }
    })
  }

  public update() {
    this.instance.update();
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {};
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }
    return new Slider(element, {
      ...options,
    });
  });
})();
