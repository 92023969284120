import { render, staticRenderFns } from "./StepFour.vue?vue&type=template&id=bc18c8fc&scoped=true&"
import script from "./StepFour.vue?vue&type=script&lang=js&"
export * from "./StepFour.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc18c8fc",
  null
  
)

export default component.exports