<template>
  <div class="row xl:items-stretch items-center">
    <div v-for="product in products" class="col-xl-3 col-md-6 col-12">
      <ul class="quote__list">
        <h2 class="heading heading--sm text-center mb-md">{{product.name.plural}}</h2>
        <li v-for="(item, itemIndex) in itemsInProduct(product.key)">
          <label class="input--checkbox">
            <input type="checkbox" checked class="checkbox">
            <span class="checkmark"></span>
            <div>
              {{item.name}}
            </div>
          </label>
          <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'quantity')" :value="item.schema.quantity" type="sm" />
          <i class="uil uil-trash-alt flex items-center" @click="removeItem(item.name)" :title="`${trans.remove} ${item.name}`"></i>
        </li>
        <button :class="{'mt-md': itemsInProduct(product.key).length > 0}" @click="addItem(product)">{{trans.add}} {{product.name.singular}}</button>
      </ul>
    </div>
  </div>
</template>
<script>
  import InputNumber from "@/component/InputNumber";
  import QuoteMixin from "@/mixins/quote.mixins";
  export default {
    name: 'StepOne',
    mixins: [QuoteMixin],
    props: {
      addItem: Function,
      removeItem: Function,
    },
    components: {
      InputNumber,
    }
  }
</script>
<style scoped lang="scss">
  @import '~@/styles/functions/all';
  .quote__list {
    text-align: center;
    margin-top: theme(spacing, md);
    height: min-content;
    padding: theme(spacing, sm);
    border: 1px solid theme(colors, primary);
    background-color: theme(colors, gray-light);
    &:last-of-type {
      margin-right: 0;
    }
    li {
      &:last-of-type {
        padding-bottom: 0;
      }
      padding-bottom: theme(spacing, sm);
      justify-content: space-between;
      display: flex;
      i {
        cursor: pointer;
        font-size: theme(fontSize, sm);
        margin-left: theme(spacing, xxs);
      }
      .input--checkbox {
        pointer-events: none;
      }
    }
    @screen xl {
      margin-right: theme(spacing, md);
      width: auto;
    }
  }
</style>
