<template>
  <div>
    <div v-for="item in itemsInProduct(product.key)">
      <slot v-bind:item="item">Default content</slot>
<!--      <Dropdown>-->
<!--        <template v-slot:target>-->
<!--          <button class="button button&#45;&#45;secondary w-full">-->
<!--            {{item.name}}-->
<!--          </button>-->
<!--        </template>-->
<!--        <template v-slot:content>-->
<!--          <div class="table-container">-->
<!--            <table v-if="product.key === 1 || product.key === 2">-->
<!--              <thead>-->
<!--              <th>{{trans.name}}</th>-->
<!--              <th>{{trans.length}}</th>-->
<!--              <th>{{trans.width}}</th>-->
<!--              <th>{{trans.thickness}}</th>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td>{{item.name}}</td>-->
<!--                <td>-->
<!--                  <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'length')"-->
<!--                               :min="250" :max="2200" :value="item.schema.length"/>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'width')"-->
<!--                               :min="20"-->
<!--                               :max="220" :value="item.schema.width"/>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'thickness')"-->
<!--                               :min="5" :max="50" :value="item.schema.thickness"/>-->
<!--                </td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->

<!--            <table v-else>-->
<!--              <thead>-->
<!--              <th>{{trans.name}}</th>-->
<!--              <th>{{trans.granulometry}}</th>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td>{{item.name}}</td>-->
<!--                <td>-->
<!--                  <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'granulometry')"-->
<!--                               :min="3" :max="4" :value="item.schema.granulometry"/>-->
<!--                </td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </template>-->
<!--      </Dropdown>-->
    </div>
  </div>
</template>
<script>
  import InputNumber from "@/component/InputNumber";
  import Dropdown from '@/component/Dropdown';
  import QuoteMixin from "@/mixins/quote.mixins";

  export default {
    name: 'ResponsiveTable',
    mixins: [QuoteMixin],
    components: {
      InputNumber,
      Dropdown
    },
    props: {
      product: Object,
    },
  }
</script>
