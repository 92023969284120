import Vue from 'vue';
import Store from '@/store';
import Contact from '@/components/Contact.vue';
import Ready from '@/utils/ready';

const selector = '[data-ref="contact"]';

(() => {
  Ready.watch(selector, (element: any) => {
    const trans = JSON.parse(element.dataset.trans);
    new Vue({
      store: Store,
      components: {
        Contact,
      },
      computed: {
        getTrans() {
          return trans;
        },
        getAttributes() {
          return element.dataset;
        }
      },
      template: `<Contact
        :locale="getAttributes.locale"
        :sample-id="getAttributes.sampleId"
        :quote-id="getAttributes.quoteId"
        :api="getAttributes.api"
        :title="getTrans.title"
        :name="getTrans.name"
        :company="getTrans.company"
        :phone="getTrans.phone"
        :email="getTrans.email"
        :subject="getTrans.subject"
        :select="getTrans.select"
        :message="getTrans.message"
        :send="getTrans.send"
      />`
    }).$mount((element as any));
  });
})();
