<template>
  <section class="section section--contact">
    <div class="container">
      <div class="contact">
        <div class="contact__background"></div>
        <div class="contact__heading">
          <h2 class="heading heading--lg">{{ title }}</h2>
        </div>
        <form class="contact__form" novalidate ref="form" @submit.prevent>
          <div class="p-xs flex justify-between items-center text-white mb-sm"
               :class="{'bg-tertiary': requestMessage.success, 'bg-danger': !requestMessage.success}"
               v-if="requestMessageVisible">
            <div class="flex items-center">
              <i class="uil uil-check mr-md text-md" v-if="requestMessage.success"></i>
              {{requestMessage.message}}
            </div>
            <i class="uil uil-times-circle text-sm cursor-pointer"
               @click="requestMessageVisible = !requestMessageVisible"></i>
          </div>
          <div class="row row-vs-sm">
            <div class="col col-12 col-lg-6">
              <div class="form-group"
                   :class="{'form-group--error': form.name.error, 'form-group--valid': !form.name.error && form.name.value}">
                <label for="name">{{ name }}</label>
                <input type="text" id="name" name="name" required v-model="form.name.value"
                       @change="checkValidity($event.target)" data-ref="inputField">
                <div class="form-error" v-if="form.name.error">{{form.name.error}}</div>
              </div>
              <div class="form-group"
                   :class="{'form-group--error': form.company.error, 'form-group--valid': !form.company.error && form.company.value}">
                <label for="company">{{ company }}</label>
                <input type="text" id="company" required v-model="form.company.value"
                       @change="checkValidity($event.target)" data-ref="inputField">
                <div class="form-error" v-if="form.company.error">{{form.company.error}}</div>
              </div>
              <div class="form-group"
                   :class="{'form-group--error': form.phone.error, 'form-group--valid': !form.phone.error && form.phone.value}">
                <label for="phone">{{ phone }}</label>
                <input type="tel" id="phone" required v-model="form.phone.value"
                       @change="checkValidity($event.target)" data-ref="inputField">
                <div class="form-error" v-if="form.phone.error">{{form.phone.error}}</div>
              </div>
              <div class="form-group"
                   :class="{'form-group--error': form.email.error, 'form-group--valid': !form.email.error && form.email.value}">
                <label for="email">{{ email }}</label>
                <input type="email" id="email" required v-model="form.email.value"
                       @change="checkValidity($event.target)" data-ref="inputField">
                <div class="form-error" v-if="form.email.error">{{form.email.error}}</div>
              </div>
            </div>
            <div class="col col-12 col-lg-6">
              <div class="flex flex-col h-full">
                <div class="form-group"
                     :class="{'form-group--error': form.subject.error, 'form-group--valid': !form.subject.error && subjectValue}">
                  <label for="subject">{{ subject }}</label>
                  <select id="subject" required v-model.number="subjectValue"
                          @change="checkValidity($event.target), form.subject.configError = ''" data-ref="inputField">
                    <option value="">{{ select }}</option>
                    <option v-for="type in requestTypes" :value="type.value">{{
                      $store.state.trans.trans[locale][type.type].requestType }}
                    </option>
                  </select>
                  <div class="form-error" v-if="form.subject.error">{{form.subject.error}}</div>
                </div>
                <div class="form-group" v-if="subjectValue">
                  <a class="button button--tertiary" data-ref="dialogOpener" :data-key="quoteId"
                     v-show="subjectValue === 1">
                    <span class="text-center" v-if="!$store.getters['quote/isValid']">{{ trans.quote.title }}</span>
                    <span class="text-center" v-else>{{trans.quote.editTitle}}</span>
                  </a>
                  <a class="button button--tertiary" data-ref="dialogOpener" :data-key="sampleId"
                     v-show="subjectValue === 2">
                    <span class="text-center" v-if="!$store.getters['sample/isValid']">{{ trans.sample.title }}</span>
                    <span class="text-center" v-else>{{trans.sample.editTitle}}</span>
                  </a>
                  <div class="form-error" v-if="form.subject.configError">
                    <span>{{form.subject.configError}}</span>
                  </div>
                  <!--                  <a class="button button&#45;&#45;tertiary" data-ref="dialogOpener" :data-key="sampleId" v-if="form.subject.value === '1'">Créez votre demande d'échantillon</a>-->
                </div>
                <div class="form-group flex-1 flex flex-col"
                     :class="{'form-group--error': form.message.error, 'form-group--valid': !form.message.error && form.message.value}">
                  <label for="message">{{ message }}</label>
                  <textarea class="flex-1" id="message" required v-model="form.message.value"
                            @change="checkValidity($event.target)" data-ref="inputField"></textarea>
                  <div class="form-error" v-if="form.message.error">{{form.message.error}}</div>
                </div>
                <input required type="hidden" ref="g-recaptcha-response" v-model="form.token.value" name="g-recaptcha-response">
                <button class="button button--plain md:self-end" type="submit" @click="recaptcha">
                  <i class="uil uil-message"></i>
                  <span class="ml-xxs">{{ send }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY });

  import VueRecaptcha from 'vue-recaptcha';
  import Scroller from "@/module/scroller";
  import axios from 'axios';
  import {defaultConfig} from '@/module/choice.runtime';
  import Choices from 'choices.js';

  export default {
    name: 'contact',
    components: { VueRecaptcha },
    props: {
      locale: {
        type: String,
        default: 'fr',
      },
      sampleId: String,
      quoteId: String,
      api: String,
      title: String,
      name: String,
      company: String,
      phone: String,
      email: String,
      subject: String,
      select: String,
      message: String,
      send: String,
    },
    data() {
      return {
        choice: null,
        scroller: null,
        requestMessageVisible: false,
        requestMessage: {
          success: true,
          message: ''
        },
        requestIsLoading: false,
        requestTypes: [
          {
            type: 'quote',
            slug: 'estimate',
            value: 1
          },
          {
            type: 'sample',
            slug: 'sample',
            value: 2
          },
          {
            type: 'information',
            slug: 'information',
            value: 3
          }
        ],
        form: {
          name: {
            value: '',
            error: '',
          },
          company: {
            value: '',
            error: '',
          },
          phone: {
            value: '',
            error: '',
          },
          email: {
            value: '',
            error: '',
          },
          subject: {
            error: '',
            configError: '',
          },
          message: {
            value: '',
            error: '',
          },
          request: {
            type: '',
            datas: {},
          },
          token: {
            value: null,
          }
        }
      }
    },
    mounted() {
      this.choice = new Choices('#subject', defaultConfig);
      this.scroller = new Scroller();
      this.addEvents();
    },
    methods: {
      async recaptcha() {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('submit');
        if (token) {
          this.form.token.value = token;
        }
        this.handleSubmit();
      },
      addEvents() {
        document.querySelector('.askList__item').addEventListener('click', (e) => {
          this.choice.setChoiceByValue('3');
          this.$store.commit('contact/setSelectValue', 3);
          this.form.subject.configError = '';
          this.scroller.scrollTo('.contact');
        })
      },
      checkValidity(target) {
        this.form[target.id].error = target.validationMessage;
      },
      handleSubmit() {
        this.$refs['form'].querySelectorAll('[data-ref="inputField"]').forEach(item => {
          this.checkValidity(item);
        });
        if (!this.$store.getters[`${this.subjectType}/isValid`] && this.subjectSlug && this.subjectType !== 'information') {
          this.form.subject.configError = this.trans[this.subjectType].notValidate;
        } else if (this.hasFormError) {
          this.scroller.scrollTo('.form-group--error');
        } else {
          document.body.classList.add('is-loading');
          this.form.request.type = this.subjectSlug;
          this.form.request.datas = this.$store.getters['contact/datas'];
          this.requestIsLoading = true;
          axios.post(this.api, this.form)
            .then((res) => {
              document.body.classList.remove('is-loading');
              this.requestMessage.message = res.data;
              this.requestMessageVisible = true;
              this.requestIsLoading = true;
            })
            .catch((err) => {
              document.body.classList.remove('is-loading');
              this.requestMessage.message = this.trans.message_not_sent;
              this.requestMessageVisible = true;
              this.requestMessage.success = false;
              this.requestIsLoading = true;
            })
        }
      },
    },
    computed: {
      hasFormError() {
        return Object.values(this.form).map(value => value.error).some(element => element);
      },
      subjectSlug() {
        const type = this.requestTypes.find(type => type.value === this.subjectValue);
        return type ? type.slug : false;
      },
      subjectType() {
        const type = this.requestTypes.find(type => type.value === this.subjectValue);
        return type ? type.type : false;
      },
      subjectValue: {
        get() {
          return this.$store.getters['contact/selectValue'];
        },
        set(newValue) {
          this.$store.commit('contact/setSelectValue', newValue);
        }
      },
      quoteValid() {
        return this.$store.getters['quote/isValid'];
      },
      sampleValid() {
        return this.$store.getters['sample/isValid'];
      },
      trans() {
        return this.$store.state.trans.trans[this.$store.state.trans.locale];
      },
      recaptchaSiteKey() {
        return process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
      }
    },
    watch: {
      subjectValue(newValue) {
        this.choice.setChoiceByValue(newValue.toString());
      },
      quoteValid: {
        immediate: true,
        deep: true,
        handler(newVal, oldVal) {
          this.form.subject.configError = ''
        }
      },
      sampleValid: {
        immediate: true,
        deep: true,
        handler(newVal, oldVal) {
          this.form.subject.configError = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/styles/functions/all.scss";
  @import "~@/styles/mixins/all.scss";

  .contact {
    position: relative;

    .contact__background {
      position: absolute;
      top: theme(spacing, xl);
      right: 0;
      left: 75px;
      height: 250px;
      background-image: url('~@/assets/contact-texture.svg');
      @include backgroundCover;
      z-index: -1;
      @screen lg {
        left: 150px;
      }
    }

    .contact__heading {
      display: flex;
      margin-bottom: theme(spacing, xl2);
      @screen lg {
        margin-bottom: theme(spacing, xl3);
      }
    }

    .contact__form {
      padding: theme(spacing, lg);
      background-color: theme(colors, gray);
      @screen lg {
        margin: 0 80px;
      }
      @screen xl {
        padding: theme(spacing, xl) 100px;
      }
    }
  }
</style>
