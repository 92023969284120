<template>
  <div class="inputNumber" v-if="type === 'default'">
    <button class="inputNumber--reduce" @click="down">-</button>
    <input type="text" @change="checkValue" :min="min" :max="max" v-model.number="inputValue">
    <button class="inputNumber--add" @click="up">+</button>
  </div>
  <div class="inputNumber inputNumber--sm" v-else-if="type === 'sm'">
    <input type="text" @change="checkValue" :min="min" :max="max" v-model.number="inputValue">
    <div class="flex flex-column">
      <button @click="up"><i class="uil uil-angle-up"></i></button>
      <button @click="down"><i class="uil uil-angle-down"></i></button>
    </div>
  </div>
</template>
<script>
  import {cloneDeep} from 'lodash';
  export default {
    name: 'InputNumber',
    data() {
      return {
        inputValue: null,
      }
    },
    mounted() {
      this.inputValue = cloneDeep(this.value);
      if (!this.inputValue) {
        this.inputValue = this.min;
      }
    },
    props: {
      type: {
        default: 'default',
        type: String,
      },
      value: Number | String,
      min: {
        default: 1,
        type: Number,
      },
      max: {
        default: 1000,
        type: Number,
      },
    },
    methods: {
      checkValue() {
        if (this.inputValue > this.max) {
          this.inputValue = this.max;
        } else if (this.inputValue < this.min) {
          this.inputValue = this.min;
        }
        this.$emit('input-number-change', this.inputValue);
      },
      up() {
        this.inputValue += 1;
        this.checkValue();
      },
      down() {
        this.inputValue -= 1;
        this.checkValue();
      }
    },
  }
</script>
<style scoped lang="scss">
  @import "~@/styles/functions/all.scss";
  @import "~@/styles/mixins/all.scss";
  .inputNumber {
    margin: auto;
    background-color: white;
    display: flex;
    padding: theme(spacing, xs);
    border: 1px solid theme(colors, primary);
    max-width: 250px;
    button {
      min-width: unset;
      height: theme(unit, lg);
      width: theme(unit, lg);
      font-size: theme(fontSize, sm);
      &.inputNumber--add {
        border: 2px solid theme(colors, success);
        color: theme(colors, success);
        background-color: white;
        &:hover {
          background-color: theme(colors, success);
          color: white;
        }
      }
      &.inputNumber--reduce {
        border: 2px solid theme(colors, danger);
        color: theme(colors, danger);
        background-color: white;
        &:hover {
          background-color: theme(colors, danger);
          color: white;
        }
      }
      &:focus {
        outline: none;
      }
    }
    input {
      border: 0;
      text-align: center;
      padding: 0;
      height: auto;
      margin-left: theme(spacing, sm);
      margin-right: theme(spacing, sm);
      min-width: 0;
    }
    &.inputNumber--sm {
      max-width: 75px;
      padding: 0;
      input {
        font-size: theme(fontSize, sm);
        margin: 0 theme(spacing, xxs);
      }
      button {
        height: 20px;
        width: 20px;
      }
    }
  }
</style>
