<template>
  <div class="dropdown">
    <div class="dropdown__opener" @click="isVisible = !isVisible">
      <slot name="target"></slot>
    </div>
    <div class="dropdown__content" v-show="isVisible">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Dropdown',
    data() {
      return {
        isVisible: false,
      }
    },
  }
</script>
<style lang="scss" scoped>
  @import '~@/styles/functions/all';
  .dropdown {
    .dropdown__opener {

    }
    .dropdown__content {
      padding: theme(spacing, sm) 0;
    }
  }
</style>
