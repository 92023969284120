import { throttle } from 'lodash';
import { gsap, Power4 } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Scroller {
  private readonly duration: number = 0.5;
  private readonly links: any;
  private readonly header: any;
  private headerHeight: any;
  private readonly scrollSections: any;
  private readonly binderSectionDetector: any;

  constructor() {
    this.links = document.querySelectorAll(`[data-scope]`);
    this.header = document.querySelector(`[data-ref="header"]`);
    this.headerHeight = this.header ? this.header.clientHeight : 0;
    this.scrollSections = document.querySelectorAll(`[data-ref="scrollSection"]`);
    this.binderSectionDetector = throttle(this.detectCurrentSection.bind(this), 100);
    if (this.links[0]) {
      this.toggleActiveLink(this.links[0].dataset.scope);
      this.detectCurrentSection();
      this.addEventListeners();
    }
    window.addEventListener('resize', throttle(this.checkWidth.bind(this), 1000));
  }

  public addEventListeners() {
    document.addEventListener('scroll', this.binderSectionDetector);
    [].forEach.call(this.links, (link: any) => {
      const scope = link.dataset.scope;
      link.addEventListener('click', () => {
        if (scope) {
          document.removeEventListener('scroll', this.binderSectionDetector);
          this.toggleActiveLink(scope);
          this.scrollTo(scope);
        }
      });
    })
  }

  public detectCurrentSection() {
    [].forEach.call(this.scrollSections, (section: any, index: number) => {
      const sectionPosY = section.getBoundingClientRect().top;
      const nextSectionPosY =
        this.scrollSections[index + 1]
          ? this.scrollSections[index + 1].getBoundingClientRect().top
          : 0
      ;
      const sectionBreakPos = sectionPosY - this.headerHeight;
      const sectionHeight =
        nextSectionPosY
          ? nextSectionPosY - sectionPosY
          : section.clientHeight
      ;
      if (sectionBreakPos <= 0 && (sectionBreakPos + sectionHeight) > 0) {
        this.toggleActiveLink(section.id);
      }
    });
  }

  public toggleActiveLink(name: string) {
    [].forEach.call(this.links, (link: any) => {
      link.classList.remove('active');
      if (link.dataset.scope === name) {
        link.classList.add('active');
      }
    });
  };

  public scrollTo(target: string) {
    gsap.to(window, {
      duration: this.duration,
      ease: Power4.easeInOut,
      scrollTo: {
        y: `#${target}`,
        offsetY: this.headerHeight,
      },
      onComplete: () => {
        document.addEventListener('scroll', this.binderSectionDetector);
      },
    });
  }

  private checkWidth() {
    this.headerHeight = this.header ? this.header.clientHeight : 0;
  }
}

(() => {
  new Scroller();
})();
