<template>
  <div>
    <div class="mb-lg" v-for="product in products" v-if="itemsInProduct(product.key).length > 0">
      <h1 class="heading heading--sm mb-sm">{{product.name.plural}}</h1>
      <table v-if="!isResponsiveView">
        <thead>
        <th style="width: 120px">{{trans.name}}</th>
        <th></th>
        <th v-if="product.key <= 2">{{trans.drill}}</th>
        <th v-if="product.key <= 2">{{trans.drillQuantity}}</th>
        <th v-if="product.key <= 2">{{trans.drillSize}}</th>
        <th  style="width: 30%">{{trans.wipingTime}}</th>
        </thead>
        <tbody>
        <tr v-for="item in itemsInProduct(product.key)">
          <td>{{item.name}}</td>
          <td></td>
          <td v-if="product.key <= 2">
            <label class="input--checkbox input--checkbox-lg justify-center"
                   v-if="item.schema.drill.active">
              <input type="checkbox" v-model="item.schema.drill.value">
              <span class="checkmark"></span>
            </label>
            <div v-else><span>{{trans.no}}</span></div>
          </td>
          <td v-if="item.key <= 2">
            <InputNumber v-if="item.schema.drill.value"
                         @input-number-change="setItemSchemaValue($event, item.name, 'drill.quantity')"
                         :min="1" :max="20" :value="item.schema.drill.quantity"/>
            <div v-else>-</div>
          </td>
          <td v-if="item.key <= 2">
            <InputNumber v-if="item.schema.drill.value"
                         @input-number-change="setItemSchemaValue($event, item.name, 'drill.size')"
                         :min="0" :max="2" :value="item.schema.drill.size"/>
            <div v-else>-</div>
          </td>
          <td>
            <select v-model.number="item.schema.wipingTime" data-select>
              <option selected :value="12">12</option>
              <option :value="18">18</option>
              <option :value="24">24</option>
              <option :value="36">36</option>
            </select>
          </td>
        </tr>
        </tbody>
      </table>
      <ResponsiveTable :product="product" :items="items" :key="product.key" v-else>
        <template v-slot:default="slotProps">
          <Dropdown>
            <template v-slot:target>
              <button class="button button--secondary w-full">
                {{slotProps.item.name}}
              </button>
            </template>
            <template v-slot:content>
              <div class="table-container">
                <table>
                  <thead>
                  <th style="width: 120px">{{trans.name}}</th>
                  <th></th>
                  <th v-if="product.key <= 2">{{trans.drill}}</th>
                  <th v-if="product.key <= 2">{{trans.drillQuantity}}</th>
                  <th v-if="product.key <= 2">{{trans.drillSize}}</th>
                  <th  style="width: 30%">{{trans.wipingTime}}</th>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{slotProps.item.name}}</td>
                    <td></td>
                    <td v-if="slotProps.item.key <= 2">
                      <label class="input--checkbox input--checkbox-lg justify-center"
                             v-if="slotProps.item.schema.drill.active">
                        <input type="checkbox" v-model="slotProps.item.schema.drill.value">
                        <span class="checkmark"></span>
                      </label>
                      <div v-else><span>{{trans.no}}</span></div>
                    </td>
                    <td v-if="slotProps.item.key <= 2">
                      <InputNumber v-if="slotProps.item.schema.drill.value"
                                   @input-number-change="setItemSchemaValue($event, slotProps.item.name, 'drill.quantity')"
                                   :min="1" :max="20" :value="slotProps.item.schema.drill.quantity"/>
                      <div v-else>-</div>
                    </td>
                    <td v-if="slotProps.item.key <= 2">
                      <InputNumber v-if="slotProps.item.schema.drill.value"
                                   @input-number-change="setItemSchemaValue($event, slotProps.item.name, 'drill.size')"
                                   :min="0" :max="2" :value="slotProps.item.schema.drill.size"/>
                      <div v-else>-</div>
                    </td>
                    <td>
                      <select v-model.number="slotProps.item.schema.wipingTime" data-select>
                        <option selected :value="12">12</option>
                        <option :value="18">18</option>
                        <option :value="24">24</option>
                        <option :value="36">36</option>
                      </select>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </Dropdown>
        </template>
      </ResponsiveTable>
    </div>
  </div>
</template>
<script>
  import InputNumber from "@/component/InputNumber";
  import QuoteMixin from '@/mixins/quote.mixins';
  import ResponsiveTable from "@/component/ResponsiveTable";
  import Dropdown from '@/component/Dropdown';

  export default {
    name: 'StepThree',
    mixins: [QuoteMixin],
    components: {
      ResponsiveTable,
      InputNumber,
      Dropdown
    }
  }
</script>
<style lang="scss" scoped>
</style>
