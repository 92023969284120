<template>
  <div>
    <div class="mb-lg" v-for="product in products"
         v-if="itemsInProduct(product.key).length > 0">
      <h1 class="heading heading--sm mb-sm">{{product.name.plural}}</h1>
      <div class="table-container">
        <table>
          <thead v-if="product.key <= 2">
          <th>{{trans.name}}</th>
          <th>{{trans.length}}</th>
          <th>{{trans.width}}</th>
          <th>{{trans.thickness}}</th>
          <th>{{trans.wipingTime}}</th>
          <th>{{trans.drill}}</th>
          <th>{{trans.drillQuantity}}</th>
          <th>{{trans.drillSize}}</th>
          <th>{{trans.quantity}}</th>
          </thead>
          <thead v-else>
          <th>{{trans.name}}</th>
          <th>{{trans.granulometry}}</th>
          <th>{{trans.wipingTime}}</th>
          <th>{{trans.quantity}}</th>
          </thead>
          <tbody v-if="product.key <= 2">
            <tr v-for="item in itemsInProduct(product.key)">
              <td>{{item.name}}</td>
              <td>{{item.schema.length}}</td>
              <td>{{item.schema.width}}</td>
              <td>{{item.schema.thickness}}</td>
              <td>{{item.schema.wipingTime}}</td>
              <td><span v-if="item.schema.drill.value">Oui</span><span v-else>Non</span></td>
              <td><span v-if="item.schema.drill.value">{{item.schema.drill.quantity}}</span><span v-else>-</span></td>
              <td><span v-if="item.schema.drill.value">{{item.schema.drill.size}}</span><span v-else>-</span></td>
              <td>{{ item.schema.quantity }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="item in itemsInProduct(product.key)">
              <td>{{item.name}}</td>
              <td>{{item.schema.granulometry}}</td>
              <td>{{item.schema.wipingTime}}</td>
              <td>{{ item.schema.quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import QuoteMixin from '@/mixins/quote.mixins';

  export default {
    name: 'StepFour',
    mixins: [QuoteMixin],
  }
</script>
<style lang="scss" scoped>
</style>
