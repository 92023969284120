const pristineConfig = {
  containerScreens: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1320px',
  },
  screens: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xl2: '1400px',
    xl3: '1600px',
  },
  gutters: {
    base: '15px',
  },
  colors: {
    primary: '#EB5A3E',
    'primary-darken': '#BE572B',
    secondary: '#01313E',
    'secondary-darken': '#001419',
    tertiary: '#A2A05A',
    gray: '#EBEBEB',
    'gray-light': '#F8F8F8',
    danger: '#E7362B',
    success: '#28a745'
  },
  unit: {
    base: '50px',
    lg: '32px',
    md: '24px',
    sm: '16px',
  },
  spacing: {
    xl3: '68px',
    xl2: '50px',
    xl: '40px',
    lg: '30px',
    md: '22px',
    sm: '18px',
    xs: '10px',
    xxs: '5px',
  },
  fontSize: {
    lg: '34px',
    md: '24px',
    sm: '16px',
    xs: '14px',
    xxs: '10px',
  },
  letterSpacing: {
    lg: '1.95px',
    md: '1.75px',
    base: '0.5px',
    sm: '0.25px',
    xs: '0.15px',
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {},
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {},
  fontWeight: {},
  height: {
    video: '300px',
    header: {
      base: '156px',
      mobile: '62px',
    }
  },
  inset: {},
  lineHeight: {},
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  width: {},
  zIndex: {},
};

module.exports = pristineConfig;
