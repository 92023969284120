<template>
  <div class="quote">
    <div class="quote__content">
      <h2 class="heading heading--md text-center mb-lg">{{ $store.state.trans.trans[locale][type].title }}</h2>
      <p class="italic text-center mb-lg">{{ $store.state.trans.trans[locale][`step${currentStep}`].title }}</p>
      <div class="quote__section" v-for="(step, index) in steps" v-if="currentStep === (index + 1)">
        <template v-if="currentStep === 1">
          <StepOne :type="type" :items="items" :products="products" :add-item="addItem" :remove-item="removeItem"/>
        </template>
        <template v-else-if="currentStep === 2">
          <StepTwo :type="type" :items="items" :products="products"/>
        </template>
        <template v-else-if="currentStep === 3">
          <StepThree :type="type" :items="items" :products="products"/>
        </template>
        <template v-else>
          <StepFour :type="type" :items="items" :products="products"/>
        </template>
      </div>
    </div>
    <div class="quote__footer">
      <div class="quote__pagination">
        <div @click="nextStep(index + 1)" class="quote__pagination__step flex"
             :class="{'text-primary': currentStep === index + 1}" v-for="(step, index) in steps">
          0{{ index + 1 }} - {{ $store.state.trans.trans[locale][`step${index+1}`].name }}
          <span class="mx-md flex items-center text-primary"
                v-if="(index + 1) !== steps">.</span>
        </div>
      </div>
      <div class="quote__submit">
        <button class="button button--tertiary mr-sm bg-primary" @click="previousStep"
                v-if="currentStep > 1">{{ $store.state.trans.trans[locale].back }}
        </button>
        <button v-if="currentStep !== steps" class="button button--tertiary"
                :class="{'button--disabled': items.length === 0}" @click="nextStep(null)">
          {{ $store.state.trans.trans[locale].next }}
        </button>
        <button v-else class="button button--tertiary" @click="handleSubmit">{{$store.state.trans.trans[locale][type].validation}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import Scroller from '@/module/scroller';
  import StepOne from "@/component/StepOne";
  import StepTwo from "@/component/StepTwo";
  import StepThree from "@/component/StepThree";
  import StepFour from "@/component/StepFour";
  import {cloneDeep} from 'lodash';

  export default {
    name: 'Quote',
    props: {
      type: {
        type: String,
        default: 'quote'
      },
      locale: String,
    },
    components: {
      StepOne,
      StepTwo,
      StepThree,
      StepFour
    },
    created() {
      this.$store.commit('trans/setLocale', this.locale);
    },
    data() {
      return {
        scroller: null,
        currentStep: 1,
        items: [],
        steps: 4,
        products: [
          {
            name: {
              plural: 'Staves',
              singular: 'Stave'
            },
            slug: 'staves',
            number: 1,
            key: 1,
          },
          {
            name: {
              plural: 'Blocks',
              singular: 'Block'
            },
            slug: 'bricks',
            number: 1,
            key: 2,
          },
          {
            name: {
              plural: 'Copeaux',
              singular: 'Copeau'
            },
            slug: 'chips',
            number: 1,
            key: 3,
          },
          {
            name: {
              plural: 'Granulés',
              singular: 'Granulé'
            },
            slug: 'pellets',
            number: 1,
            key: 4,
          },
        ]
      }
    },
    methods: {
      addItem(product) {
        const item = cloneDeep(product);
        const schema = product.key === 1 || product.key === 2 ? cloneDeep(this.$store.state.quote.schema1) : cloneDeep(this.$store.state.quote.schema2);
        product.number += 1;
        this.items = [...this.items, {
          name: `${item.name.singular}-${item.number}`,
          key: item.key,
          slug: item.slug,
          // checked: true,
          schema: schema,
        }];
      },
      removeItem(name) {
        this.items = this.items.filter(item => item.name !== name);
      },
      nextStep(index = null) {
        if (this.items.length !== 0) {
          if (index) {
            this.currentStep = index;
          } else if (this.currentStep < this.steps) {
            this.currentStep += 1;
          }
        }
      },
      previousStep() {
        this.currentStep = this.currentStep - 1;
      },
      handleSubmit() {
        const schema = {
          staves: [],
          chips: [],
          bricks: [],
          pellets: []
        }
        this.items.forEach(item => {
          schema[item.slug] = [...schema[item.slug], item.schema];
        });
        if (this.type === 'quote') {
          this.$store.commit('quote/submitQuote', schema);
        } else {
          this.$store.commit('sample/submitSample', schema);
        }
        this.closeDialog();
      },
      closeDialog() {
        const scroller = new Scroller();
        document.querySelector('.dialog--open').classList.remove('dialog--open');
        document.body.classList.remove('overflow-y-hidden');
        scroller.scrollTo('#contact');
      }
    },
  }
</script>
<style lang="scss">
  @import '~@/styles/functions/all';
  .quote__section {
    table {
      width: 100%;

      td {
        text-align: center;
        .choices {
          margin: auto;
        }
      }
    }
    .choices {
      max-width: 250px;
      .choices__list--dropdown {
        .choices__item--selectable {
          padding: theme(spacing, xs);
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import '~@/styles/functions/all';

  .quote {
    height: 100%;
    display: flex;
    flex-direction: column;

    .quote__content {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      @media screen and (max-width: theme(screens, md)) {
        .heading {
          font-size: theme(fontSize, md);
        }
      }

      .quote__section {
        flex: 1;
        width: 100%;
      }
    }

    .quote__footer {
      padding-top: theme(spacing, lg);

      .quote__pagination {
        margin-bottom: theme(spacing, sm);
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .quote__pagination__step {
          cursor: pointer;
          text-transform: uppercase;
          font-size: theme(fontSize, xxs);
          letter-spacing: theme(letterSpacing, md);
          justify-content: center;
          span {
            display: none;
          }
        }
        @screen lg {
          flex-direction: row;
          .quote__pagination__step {
            justify-content: inherit;
            span {
              display: block;
            }
          }
        }
      }
    }

    .quote__submit {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        @media screen and (max-width: theme(screens, md)) {
          font-size: theme(fontSize, xxs);
        }
      }
    }
  }
</style>
