/* Add polyfills from the polyfills folder (order matters) */
import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/ObjectFit';
import '@/polyfills/NodeContains';
import '@/polyfills/Append';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

import 'alpinejs/dist/alpine-ie11';
import 'css-element-queries';

ElementQueries.listen();

/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

/** Unicons: https://iconscout.com/unicons */
import '@iconscout/unicons/css/unicons.css';

/* Import Styles */
import '@/styles/main.scss';
import { ElementQueries } from 'css-element-queries';

/* Auto Imports */
const srcContext = require.context(
  '@',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
  '@root/templates',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
templatesContext.keys().forEach(templatesContext);

/* Auto Imports */
// [
//   '@',
//   '@root/templates',
// ].forEach((path) => {
//   const src = require.context(path, true, /\.(runtime|asset)\.(.*?)$/);
//   src.keys().forEach(src);
// });
