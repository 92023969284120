const ContactStore = {
  namespaced: true,
  state: {
    selectValue: '',
    datas: [],
  },
  getters: {
    selectValue(state: any) {
      return state.selectValue;
    },
    datas(state: any) {
      return state.datas;
    }
  },
  mutations: {
    setSelectValue(state: any, value: Array<object>) {
      state.selectValue = value;
    },
    setDatas(state: any, value:Array<object>) {
      state.datas = value;
    }
  }
}

export {ContactStore};
