import {set} from 'lodash';

const QuoteMixin = {
  props: {
    type: {
      type: String,
      default: 'quote',
    },
    products: Array,
    items: Array,
  },
  methods: {
    itemsInProduct(key: any) {
      return (this as any).items.filter((item: any) => item.key === key);
    },
    // checkedItemsInProduct(key: any) {
    //   return (this as any).items.filter((item: any) => item.key === key && item.checked);
    // },
    setItemSchemaValue(value: any, name: string, keys: string) {
      const item = (this as any).items.find((item: any) => item.name === name);
      set(item.schema, keys, value);
    }
  },
  computed: {
    checkedItems() {
      return (this as any).items.filter((item: any) => item.checked);
    },
    trans() {
      return (this as any).$store.state.trans.trans[(this as any).$store.state.trans.locale];
    },
    isResponsiveView() {
      return window.innerWidth <= 998;
    }
    // products() {
    //   return (this as any).type === 'quote' ? (this as any).$store.getters['quote/products'] : (this as any).$store.getters['sample/products'];
    // }
  }
};

export default QuoteMixin;
