import Ready from '@/utils/ready';

const selector = '[data-ref="header"]';

Ready.watch(selector, (element: HTMLElement) => {
  document.addEventListener('wheel', (e: any) => {
    if (window.scrollY > 200 && !document.body.classList.contains('overflow-y-hidden') && window.innerWidth >= 992) {
      if (e.deltaY > 0) {
        element.classList.add('is-hide');
      } else {
        element.classList.remove('is-hide');
      }
    }
  });
});
