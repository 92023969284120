import Ready from '@/utils/ready';
import Dialog from '@/module/dialog';

const selector = '[data-ref="dialogOpener"]';

(() => {
  Ready.watch(selector, (element: any) => {
    const dialog = (document as any).querySelector(`[data-ref="dialog"][data-key="${element.dataset.key}"]`);
    if (dialog) {
      const item = new Dialog(dialog);
      element.addEventListener('click', () => {
        item.open();
      });
    }
  });
})();
