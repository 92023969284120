import Ready from '@/utils/ready';

const selector = '[data-ref="form"]';

class FormValidator {
  el: any = null;
  inputs: any = [];
  errorDivName: string = 'form-error';

  constructor(el: any) {
    this.el = el;
    this.findInput();
    this.watchInput();
    this.watchSubmit();
  }

  private findInput() {
    [].forEach.call(this.el.querySelectorAll('input[required]'), (input: any) => {
      this.inputs = [...this.inputs, input];
    });
    [].forEach.call(this.el.querySelectorAll('select[required]'), (input: any) => {
      this.inputs = [...this.inputs, input];
    });
    [].forEach.call(this.el.querySelectorAll('textarea[required]'), (input: any) => {
      this.inputs = [...this.inputs, input];
    });
  }

  private watchInput() {
    [].forEach.call(this.inputs, (input: any) => {
      input.addEventListener('change', () => {
        this.initError(input);
      });
    });
  }

  private initError(input: any) {
    const formGroup = input.closest('.form-group');
    const parentHasError = formGroup.querySelector(`.${this.errorDivName}`) || formGroup.querySelector('.list--error');
    if (parentHasError) {
      formGroup.classList.remove('form-group--error');
      parentHasError.remove();
    }
    if (!input.validity.valid) {
      formGroup.classList.remove('form-group--valid');
      formGroup.classList.add('form-group--error');
      this.createError(input);
    } else {
      formGroup.classList.add('form-group--valid');
    }
  }

  private createError(input: any) {
    const error = document.createElement('div');
    error.innerHTML = input.validationMessage;
    error.classList.add(this.errorDivName);
    input.closest('.form-group').append(error);
  }

  private watchSubmit() {
    this.el.addEventListener('submit', (e: any) => {
      [].forEach.call(this.inputs, (input: any) => {
        if (!input.validity.valid) {
          this.initError(input);
          e.preventDefault ? e.preventDefault() : e.returnValue = false;
        }
      });
    });
  }
}

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    new FormValidator(element);
  });
})();
