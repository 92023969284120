const TransStore = {
  namespaced: true,
  state: {
    locale: '',
    trans: {
      en: {
        sample: {
          title: 'Create your custom sample',
          editTitle: 'Edit your custom sample',
          validation: 'Validate my sample',
          requestType: 'Request a sample',
          notValidate: 'You have not validated your sample request'
        },
        quote: {
          title: 'Create your custom quote',
          editTitle: 'Edit your custom quote',
          validation: 'Validate my quote',
          requestType: 'Ask for a quotation',
          notValidate: 'You didn\'t validate your request for a quote'
        },
        information: {
          requestType: 'Request for information',
        },
        step1: {
          title: 'Add the type of oenological wood (several choices are possible).',
          name: 'Choice of product',
        },
        step2: {
          title: 'Choose the dimensions for each wood.',
          name: 'Dimensions',
        },
        step3: {
          title: 'Add options and select the quantity.',
          name: 'Options',
        },
        step4: {
          title: 'Check the previously completed information on each wood.',
          name: 'Abstract',
        },
        add: 'Add a',
        next: 'Next',
        back: 'Back',
        remove: 'Remove',
        name: 'Name',
        length: 'Length (mm)',
        width: 'Width (mm)',
        thickness: 'Thickness (mm)',
        wipingTime: 'Wiping Time (month)',
        drill: 'Drilling',
        drillQuantity: 'Hole quantity',
        drillSize: 'Hole size (mm)',
        quantity: 'Quantity',
        granulometry: 'Size/Granulometry (mm)',
        no: 'No drilling option',
        message_not_sent: 'A mistake has been made. Your message was not sent'
      },
      fr: {
        sample: {
          title: 'Créez votre échantillon personnalisé',
          validation: 'Valider mon échantillon',
          requestType: 'Demande d\'échantillon',
          editTitle: 'Modifiez votre échantillon personnalisé',
          notValidate: 'Vous n\'avez pas validé votre demande d\'échantillon'
        },
        quote: {
          title: 'Créez votre devis personnalisé',
          validation: 'Valider mon devis',
          requestType: 'Demande de devis',
          editTitle: 'Modifiez votre devis personnalisé',
          notValidate: 'Vous n\'avez pas validé votre demande de devis'
        },
        information: {
          requestType: 'Demande d\'information',
        },
        step1: {
          title: 'Ajoutez le type de bois oenologiques (plusieurs choix sont possibles).',
          name: 'Choix du produit',
        },
        step2: {
          title: 'Choisissez les dimensions pour chaque bois.',
          name: 'Dimensions',
        },
        step3: {
          title: 'Ajoutez des options et selectionnez la quantité.',
          name: 'Options',
        },
        step4: {
          title: 'Vérifiez les informations remplies précédement sur chaque bois.',
          name: 'Résumé',
        },
        add: 'Ajouter un',
        next: 'Suivant',
        back: 'Retour',
        remove: 'Supprimer',
        name: 'Nom',
        length: 'Longueur (mm)',
        width: 'Largeur (mm)',
        thickness: 'Épaisseur (mm)',
        wipingTime: 'Durée de ressuyage (mois)',
        drill: 'Perçage',
        drillQuantity: 'Nombre de trous',
        drillSize: 'Taille des trous (mm)',
        quantity: 'Quantité',
        granulometry: 'Taille/Granulométrie (mm)',
        no: 'Pas d\'options de perçage',
        message_not_sent: 'Une erreur est survenue. Votre message n\'a pas été envoyé'
      },
      de: {
        sample: {
          title: 'Erstellen Sie Ihre benutzerdefinierte Probe',
          validation: 'Meine Probe validieren',
          requestType: 'Muster anfordern',
          editTitle: 'Modifizieren Sie Ihr benutzerdefiniertes Muster',
          notValidate: 'Sie haben Ihren Antrag auf eine Probe nicht bestätigt'
        },
        quote: {
          title: 'Erstellen Sie Ihr individuelles Angebot',
          validation: 'Mein Zitat bestätigen',
          requestType: 'Kostenvoranschlag anfordern',
          editTitle: 'Ändern Sie Ihr persönliches Angebot',
          notValidate: 'Sie haben Ihre Anfrage für ein Angebot nicht validiert'
        },
        information: {
          requestType: 'Anforderung von Informationen',
        },
        step1: {
          title: 'Fügen Sie die Art des önologischen Holzes hinzu (mehrere Auswahlmöglichkeiten sind möglich).',
          name: 'Wahl des Produkts',
        },
        step2: {
          title: 'Wählen Sie die Abmessungen für jedes Holz.',
          name: 'Abmessungen',
        },
        step3: {
          title: 'Fügen Sie Optionen hinzu und wählen Sie die Menge aus.',
          name: 'Optionen',
        },
        step4: {
          title: 'Überprüfen Sie die zuvor ausgefüllten Informationen zu jedem Holz.',
          name: 'Kurzfassung',
        },
        add: 'Hinzufügen einer ',
        next: 'Nächste',
        back: 'Zurück',
        remove: 'löschen',
        name: 'Name',
        length: 'Länge (mm)',
        width: 'Breite (mm)',
        thickness: 'Dicke (mm)',
        wipingTime: 'Farbeindringzeit (Monat)',
        drill: 'Bohren',
        drillQuantity: 'Anzahl der Löcher',
        drillSize: 'Lochgröße (mm)',
        quantity: 'Anzahl',
        granulometry: 'Korngröße (mm)',
        no: 'Keine Bohroption',
        message_not_sent: 'Es wurde ein Fehler gemacht. Ihre Nachricht wurde nicht gesendet'
      },
    }
  },
  mutations: {
    setLocale(state: any, lang: string) {
      state.locale = lang;
    }
  }
}

export {TransStore};
