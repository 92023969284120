import '@/assets/logo.svg';
import '@/assets/logo-horizontal-white.svg';
import '@/assets/logo-horizontal-dark.svg';

import '@/assets/cardIcon1.svg';
import '@/assets/cardIcon1_reverse.svg';
import '@/assets/cardIcon2.svg';
import '@/assets/cardIcon2_reverse.svg';
import '@/assets/cardIcon3.svg';
import '@/assets/cardIcon3_reverse.svg';

import '@/assets/section1-bg.jpg';

import '@/assets/section2-left-bg.jpg';
import '@/assets/section2-right-bg.jpg';

import '@/assets/section3-bg.jpg';
import '@/assets/section3-left-bg.jpg';
import '@/assets/section3-middle-bg.jpg';
import '@/assets/section3-right-bg.jpg';

import '@/assets/section4-left-bg.jpg';
import '@/assets/section4-middle-bg.jpg';
import '@/assets/section4-right-bg.jpg';

import '@/assets/section5-bg.jpg';

import '@/assets/section6-bg.jpg';

import '@/assets/logo-dark.svg';

import '@/assets/video-placeholder.asset.mp4';

// Favicon
import '@/assets/favicon-16x16.png';
import '@/assets/favicon-32x32.png';
// import '@/assets/icons/favicon-96x96.png';
// import '@/assets/icons/favicon-128x128.png';
import '@/assets/icons/apple-touch-icon.png';
