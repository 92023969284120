import Ready from '@/utils/ready';
import { throttle } from 'lodash';

const selector = '[data-ref="timeline"]';

class Timeline {
  el: any;

  constructor(element: HTMLElement) {
    this.el = element;
    this.checkWidth();
    window.addEventListener('resize', throttle(this.checkWidth.bind(this), 1000));
  }

  private checkWidth() {
    if (window.innerWidth >= 1200) {
      this.el.style.display = 'block';
    }
    else {
      this.el.style.display = 'none';
    }
  }
}

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    new Timeline(element);
  });
})();
