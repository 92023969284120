const SampleStore = {
  namespaced: true,
  state: {
    estimates: [],
    isValid: false,
  },
  getters: {
    isValid(state: any) {
      return state.isValid;
    },
    estimates(state: any) {
      return state.estimates;
    }
  },
  mutations: {
    submitSample(state: any, value: Array<object>) {
      state.estimates = value;
      state.isValid = true;
      (this as any).commit('contact/setDatas', value);
      (this as any).commit('contact/setSelectValue', 2);
    },
  }
}

export {SampleStore};
