import Vue from 'vue';
import Vuex from 'vuex';

import {QuoteStore} from '@/store/quote.store';
import {ContactStore} from '@/store/contact.store';
import {TransStore} from '@/store/trans.store';
import {SampleStore} from "@/store/sample.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    quote: QuoteStore,
    contact: ContactStore,
    trans: TransStore,
    sample: SampleStore
  },
});
