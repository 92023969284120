<template>
  <div class="table-container">
    <table v-if="product.key === 1 || product.key === 2">
      <thead>
      <th style="width: 120px">{{trans.name}}</th>
      <th>{{trans.length}}</th>
      <th>{{trans.width}}</th>
      <th>{{trans.thickness}}</th>
      </thead>
      <tbody>
      <tr v-for="(item, index) in itemsInProduct(product.key)">
        <td>{{item.name}}</td>
        <td>
          <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'length')"
                       :min="250" :max="2200" :value="item.schema.length"/>
        </td>
        <td>
          <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'width')"
                       :min="20"
                       :max="220" :value="item.schema.width"/>
        </td>
        <td>
          <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'thickness')"
                       :min="5" :max="50" :value="item.schema.thickness"/>
        </td>
      </tr>
      </tbody>
    </table>

    <table v-else>
      <thead>
      <th style="width: 120px">{{trans.name}}</th>
      <th></th>
      <th style="width: 30%">{{trans.granulometry}}</th>
      </thead>
      <tbody>
      <tr v-for="item in itemsInProduct(product.key)">
        <td>{{item.name}}</td>
        <td></td>
        <td>
          <InputNumber @input-number-change="setItemSchemaValue($event, item.name, 'granulometry')"
                       :min="3" :max="4" :value="item.schema.granulometry"/>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import InputNumber from "@/component/InputNumber";
  import QuoteMixin from '@/mixins/quote.mixins';

  export default {
    name: 'QuoteTable',
    mixins: [QuoteMixin],
    components: {
      InputNumber
    },
    mounted() {
      this.test
    },
    props: {
      product: Object,
    },
    methods: {
      setItemSchemaValue(event, name, prop) {
        const item = this.items.find(item => item.name === name);
        item.schema[prop] = event;
      }
    }
  }
</script>
