import Ready from '@/utils/ready';


const selector = '[data-ref="carrousel"]';

class Carrousel {
  private el: HTMLElement;

  private pagination: HTMLElement | null;

  private interval: any = null;

  private paginationItems: HTMLCollection;

  private length: any = 0;

  private current: any = 0;

  private next: any = 0;

  private working: any = false;

  private durations = {
    auto: 5000,
    slide: 1400,
  };

  private dom = {
    wrapper: {},
    container: null,
    project: [],
    current: null,
    next: null,
    arrow: null,
  };

  private readonly isIE: any;

  constructor(element: HTMLElement) {
    this.el = element;
    this.pagination = this.el.querySelector('.carrousel__pagination > [data-ref="pagination"]');
    this.paginationItems = (this as any).pagination.querySelectorAll('.pagination__item');
    this.dom.wrapper = this.el;
    this.dom.project = Array.from(this.el.querySelectorAll('.carrousel__slide'));
    this.length = this.dom.project.length;
    this.isIE = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
    this.addEvents();
    this.init();
  }

  private init() {
    this.dom.project.forEach((slide: any) => {
      const item = slide;
      item.style.zIndex = 10;
    });
    this.dom.current = this.dom.project[this.current];
    this.dom.next = this.dom.project[this.current + 1];
    (this.dom.current as any).style.zIndex = 30;
    (this.dom.next as any).style.zIndex = 20;
    this.setInterval();
  }

  private process() {
    this.working = true;
    this.dom.next = this.dom.project[this.next];
    (this.dom.current as any).style.zIndex = 30;
    (this.dom.next as any).style.zIndex = 20;
    (this.dom.current as any).classList.add('carrousel__slide-hide');
    setTimeout(() => {
      (this.dom.current as any).style.zIndex = 10;
      (this.dom.next as any).style.zIndex = 30;
      (this.dom.current as any).classList.remove('carrousel__slide-hide');
      this.dom.current = this.dom.next;
      this.current = this.next;
      this.working = false;
    }, this.isIE ? 0 : this.durations.slide);
  }

  private addEvents() {
    (this as any).paginationItems.forEach((item: any, index: number) => {
      item.addEventListener('click', (e: any) => {
        if (this.current === index) {
          clearInterval(this.interval);
          this.setInterval();
        } else if (!this.working) {
          clearInterval(this.interval);
          this.slideToNext(index);
          this.setInterval();
        }
      })
    })
  }

  private clearPagination() {
    (this as any).paginationItems.forEach((item: any) => {
      item.classList.remove('pagination__item--active');
    });
  }

  private setInterval() {
    this.interval = setInterval(() => {
      this.slideToNext();
    }, this.durations.auto);
  }

  private selectPagination() {
    this.clearPagination();
    (this as any).paginationItems[this.next].classList.add('pagination__item--active');
  }

  private slideToNext(nextSlide: any = null) {
    this.next = nextSlide || nextSlide === 0 ? nextSlide : (this.current + 1) % this.length;
    this.process();
    this.selectPagination();
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    const carrousel = new Carrousel(element);
  });
})();
