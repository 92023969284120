const QuoteStore = {
  namespaced: true,
  state: {
    estimates: [],
    isValid: false,
    schema1: {
      length: 250,
      width: 20,
      thickness: 5,
      wipingTime: 18,
      drill: {
        value: true,
        active: true,
        quantity: 1,
        size: 1,
      },
      quantity: 1,
    },
    schema2: {
      granulometry: 3,
      wipingTime: 18,
      quantity: 1,
      drill: {
        value: false,
        active: false,
      },
    }
  },
  getters: {
    isValid(state: any) {
      return state.isValid;
    },
    products(state: any) {
      return state.products;
    },
    estimates(state: any) {
      return state.estimates;
    }
  },
  mutations: {
    submitQuote(state: any, value: Array<object>) {
      state.estimates = value;
      state.isValid = true;
      (this as any).commit('contact/setDatas', value);
      (this as any).commit('contact/setSelectValue', 1);
    },
  }
}

export {QuoteStore};
