<template>
  <div>
    <div class="mb-lg" v-for="product in products"
         v-if="itemsInProduct(product.key).length > 0">
      <h1 class="heading heading--sm mb-sm">{{product.name.plural}}</h1>
      <QuoteTable :product="product" :items="items" :key="product.key" v-if="!isResponsiveView"/>
      <ResponsiveTable :product="product" :items="items" :key="product.key" v-else>
        <template v-slot:default="slotProps">
          <Dropdown>
            <template v-slot:target>
              <button class="button button--secondary w-full">
                {{slotProps.item.name}}
              </button>
            </template>
            <template v-slot:content>
              <div class="table-container">
                <table v-if="product.key === 1 || product.key === 2">
                  <thead>
                  <th>{{trans.name}}</th>
                  <th>{{trans.length}}</th>
                  <th>{{trans.width}}</th>
                  <th>{{trans.thickness}}</th>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{slotProps.item.name}}</td>
                    <td>
                      <InputNumber
                        @input-number-change="setItemSchemaValue($event, slotProps.item.name, 'length')"
                        :min="250" :max="2200" :value="slotProps.item.schema.length"/>
                    </td>
                    <td>
                      <InputNumber
                        @input-number-change="setItemSchemaValue($event, slotProps.item.name, 'width')"
                        :min="20"
                        :max="220" :value="slotProps.item.schema.width"/>
                    </td>
                    <td>
                      <InputNumber
                        @input-number-change="setItemSchemaValue($event, slotProps.item.name, 'thickness')"
                        :min="5" :max="50" :value="slotProps.item.schema.thickness"/>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <table v-else>
                  <thead>
                  <th>{{trans.name}}</th>
                  <th>{{trans.granulometry}}</th>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{slotProps.item.name}}</td>
                    <td>
                      <InputNumber
                        @input-number-change="setItemSchemaValue($event, slotProps.item.name, 'granulometry')"
                        :min="3" :max="4" :value="slotProps.item.schema.granulometry"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </Dropdown>
        </template>
      </ResponsiveTable>
    </div>
  </div>
</template>
<script>
  import ResponsiveTable from "@/component/ResponsiveTable";
  import InputNumber from '@/component/InputNumber';
  import QuoteTable from '@/component/QuoteTable';
  import Dropdown from '@/component/Dropdown';
  import QuoteMixin from '@/mixins/quote.mixins';

  export default {
    name: 'StepTwo',
    components: {
      ResponsiveTable,
      QuoteTable,
      Dropdown,
      InputNumber
    },
    mixins: [QuoteMixin],
  }
</script>
<style lang="scss" scoped>
</style>
