import Ready from '@/utils/ready';


const selector = '[data-ref~="autoplay-video"]';

class AutoplayVideo {
  el: HTMLVideoElement | null = null
  constructor(element: HTMLVideoElement) {
    this.el = element;
    this.addEventListeners();
  }

  private addEventListeners() {
    window.addEventListener('scroll', () => {
      if (this.el) {
        if (this.elementInViewport()) {
          this.el.play();
        } else {
          this.el.pause();
        }
      }
    });
  }

  private elementInViewport() {
    if (this.el) {
      let el = (this.el as any);
      let top = el.offsetTop;
      let left = el.offsetLeft;
      let width = el.offsetWidth;
      let height = el.offsetHeight;

      while(el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height/5) <= (window.pageYOffset + window.innerHeight) &&
        (left + width/5) <= (window.pageXOffset + window.innerWidth)
      );
    }
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    new AutoplayVideo(element);
  });
})();
