import Ready from '@/utils/ready';
import Store from '@/store';
import Quote from "@/component/Quote.vue";
import Vue from 'vue';

const selector = '[data-ref="quote"]';

Ready.watch(selector, (element: any) => {
  new Vue(({
    store: Store,
    computed: {
      type() {
        return element.dataset.type;
      },
      getAttributes() {
        return element.dataset;
      }
    },
    components: {
      Quote,
    },
    template: `<Quote :type="type" :locale="getAttributes.locale" />`,
  })).$mount(element);
});
